import React, { useContext } from 'react';

// context
import { DeviceContext } from '~/contexts/DeviceContext';

// utils
import { getWebsitePlaceholderAsset } from '~/components/manage/EditWebsite/common/HeroCustomizer/utils';

// components
import WebsiteSlideshow from '~/components/publicWebsiteV2/common/WebsiteSlideshow';

// types
import type { MultiPageHeroVariantProps } from '~/components/publicWebsiteV2/common/MultiPageHeroContainer/types';

// styles
import { BaseHero } from '~/components/publicWebsiteV2/common/MultiPageHeroContainer/MultiPageHeroContainer.styles';

const MultiPageHeroSlideshow: React.FC<MultiPageHeroVariantProps> = ({
  images,
  inPreview,
  overrideMobileHeight,
}) => {
  const { device } = useContext(DeviceContext);

  const isDesktop = inPreview === 'DESKTOP' || (!inPreview && device?.isDesktop());

  const slideshowImages = [
    getWebsitePlaceholderAsset('SLIDESHOW_MULTI_PAGE', isDesktop, 1),
    getWebsitePlaceholderAsset('SLIDESHOW_MULTI_PAGE', isDesktop, 2),
  ];

  if (images && images.length > 0) {
    images.forEach((image) => {
      if (image.position && image.image_url) {
        slideshowImages[image.position - 1] = image.image_url;
      }
    });
  }

  return (
    <BaseHero overrideMobileHeight={overrideMobileHeight} data-testid="MultiPageHeroSlideshow">
      <WebsiteSlideshow slideshowImages={slideshowImages} inPreview={inPreview} />
    </BaseHero>
  );
};

export default MultiPageHeroSlideshow;
